<template>
  <div id="form-crud-scenario">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    <div class="card border-0 shadow p-3">
      <form @submit.prevent="initSubmit">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="lastname">{{ $t('common.name') }} *</label>
            <input class="form-control" id="lastname" v-model="scenario.name" type="text">
            <div v-if="errorsForm.name">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.name.errors" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="form-min-quantity">{{ $t('pages.scenario.qtyMin') }} *</label>
            <input class="form-control" id="form-min-quantity" v-model="scenario.minQuantity" type="number" step="0.01">
            <div v-if="errorsForm.minQuantity">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.minQuantity.errors" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="form-max-quantity">{{ $t('pages.scenario.qtyMax') }} *</label>
            <input class="form-control" id="form-max-quantity" v-model="scenario.maxQuantity" type="number" step="0.01">
            <div v-if="errorsForm.maxQuantity">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.maxQuantity.errors" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="form-min-height">{{ $t('pages.scenario.heightMin') }} *</label>
            <input class="form-control" id="form-min-height" v-model="scenario.minHeight" type="number" step="0.01">
            <div v-if="errorsForm.minHeight">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.minHeight.errors" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="form-max-height">{{ $t('pages.scenario.heightMax') }} *</label>
            <input class="form-control" id="form-max-height" v-model="scenario.maxHeight" type="number" step="0.01">
            <div v-if="errorsForm.maxHeight">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.maxHeight.errors" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="form-min-width">{{ $t('pages.scenario.widthMin') }} *</label>
            <input class="form-control" id="form-min-width" v-model="scenario.minWidth" type="number" step="0.01">
            <div v-if="errorsForm.minWidth">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.minWidth.errors" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="form-max-width">{{ $t('pages.scenario.widthMax') }} *</label>
            <input class="form-control" id="form-max-width" v-model="scenario.maxWidth" type="number" step="0.01">
            <div v-if="errorsForm.maxWidth">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.maxWidth.errors" :key="index">{{ error }}</p>
            </div>
          </div>
        </div>

        <h4 class="mt-3 mb-3">{{ $t('common.relationships') }}</h4>
        <hr/>

        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="select-paper">{{ $t('common.paper') }} *</label>
            <select id="select-paper" class="form-control" v-model="selectPaper" @input="changePaper">
              <option v-for="(paper, index) in papers" :key="index" :value="paper.id">
                {{ paper.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="select-finishing">{{ $t('common.finishing') }} *</label>
            <select id="select-finishing" class="form-control" v-model="selectFinishing" @input="changeFinishing">
              <option v-for="(finishing, index) in finishes" :key="index" :value="finishing.id">
                {{ finishing.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="select-category">{{ $t('common.singleCategory') }} *</label>
            <select id="select-category" class="form-control" v-model="selectCategory" @input="changeCategory">
              <option v-for="(category, index) in categories" :key="index" :value="category.id">
                {{ category.name }}
              </option>
            </select>
          </div>
        </div>

        <h4 class="mt-3 mb-3">Config</h4>
        <hr/>

        <div class="col-12 col-md-6">
          <div class="form-group">
            <label>{{ $t('pages.scenario.activatedScenario') }}</label>

            <div class="form-check">
              <input class="form-check-input" type="radio" id="status-yes" v-model="scenario.status" :checked="scenario.status === true"  v-bind:value="true">
              <label class="form-check-label" for="status-yes">
                {{ $t('common.yes') }}
              </label>
            </div>
            <div class="form-check" >
              <input class="form-check-input" type="radio" id="status-no" v-model="scenario.status" :checked="scenario.status === false" v-bind:value="false">
              <label class="form-check-label" for="status-no">
                {{ $t('common.no') }}
              </label>
            </div>
          </div>
        </div>

        <input type="submit" class="btn mt-4 mb-2 btn-success" :value="valueButtonSubmit" />
      </form>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  props: {
    scenario: Object,
    papers: Array,
    selectedPaper: Number,
    finishes: Array,
    selectedFinishing: Number,
    categories: Array,
    selectedCategory: Number,
    id: Number,
    actionForm: String
  },
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      selectPaper: this.selectedPaper,
      selectFinishing: this.selectedFinishing,
      selectCategory: this.selectedCategory,
      errorsForm: [],
    }
  },
  watch: {
    selectedPaper: function() {
      this.selectPaper = this.selectedPaper
    },
    selectedFinishing: function() {
      this.selectFinishing = this.selectedFinishing
    },
    selectedCategory: function() {
      this.selectCategory = this.selectedCategory
    }
  },
  created: function() {

  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*----------------- SELECT ------------------*/
    changePaper() {
      this.$emit('selectPaper',this.scenario.paper)
    },
    changeFinishing() {
      this.$emit('selectFinishing',this.scenario.finishing)
    },
    changeCategory() {
      this.$emit('selectCategory',this.scenario.category)
    },
    /*----------------- SUBMIT ------------------*/
    initSubmit()
    {
      // If Scenario (create or update)
      if(this.actionForm === 'edit') {
        this.edit()
      }
    },
    /**
     * Update Scenario (submit form)
     */
    edit() {
      this.showLoader()

      // Handle var
      this.scenario.paper = this.selectPaper
      this.scenario.finishing = this.selectFinishing
      this.scenario.category = this.selectCategory

      this.$store.dispatch('updateScenario', { id: this.scenario.id, scenario: this.scenario })
          .then(() => {
            this.hideLoader()
            this.redirectAndRefresh()
          })
          .catch((errors) => {
            this.hideLoader()
            this.errorsForm = errors
          })
    },
    /**
     * Redirect to scenario-list after submit
     * Refresh ErrorsForm
     */
    redirectAndRefresh()
    {
      // Redirect
      this.$router.push({ name: 'scenario-index' })

      // Refresh errors form
      this.errorsForm = []
    }
  },
  computed: {
    /**
     * Display value for button submit
     * @returns {string}
     */
    valueButtonSubmit() {
      let value = ''
      if(this.actionForm === 'edit')
      {
        value = this.$t('pages.scenario.editScenario')
      }

      return value
    },
    ...mapState(['ScenarioModule'])
  },
  components: {
    Loading: () => import('vue-loading-overlay')
  }
}
</script>
